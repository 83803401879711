import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from './footer';
import ContactSection from './contact-section';
import FooterSmall from './footer-small';
import SubscribeSection from './subscribe-section';

import 'font-awesome/css/font-awesome.css';
//import '../css/lightbox.css';
import 'lightbox2/dist/css/lightbox.css';
import '../css/reset.css';
import '../css/uikit.min.css';
import '../css/bootstrap.min.css';
import '../css/style.css';
import '../css/specific.css';

const Uikit = typeof window !== 'undefined' ? require('uikit') : null;
const bootstrap = typeof window !== 'undefined' ? require('bootstrap') : null;
const grid = typeof window !== 'undefined' ? require('../js/grid.min') : null;
const lightbox = typeof window !== 'undefined' ? require('lightbox2/dist/js/lightbox') : null;
const custom = typeof window !== 'undefined' ? require('../js/custom') : null;
const stickr = typeof window !== 'undefined' ? require('stickr.js/jquery.stickr') : null;

if (lightbox) {
  lightbox.option({
    resizeDuration: 200,
    wrapAround: true,
    positionFromTop: 120
  });
}
/*
import '../js/lightbox.min' > UIKIT lightbox
//import '../js/subscribe';
//import '../js/contact';
    <link rel="stylesheet" href="css/ionicons.min.css" /> 
    <script src="js/modernizr-custom.js"></script>
*/

const filterData = (data, lang) => (
  data.edges
    .filter(({ node }) => node.lang === lang)
    .map(({ node }) => ({[node.key]: node.text}))
    .reduce((acc, item) => ({ ...acc, ...item }), {})
)

const Layout = ({ 
  lang = 'en',
  children, 
  showContact = true, 
  subscribeTitle = null, 
  subscribeSubtitle = null,
  alternateLang = [],
  whatsappText = null
}) => {
  const data = useStaticQuery(graphql`
    query {
      subscribeData: allTranslation(filter: {key: {regex: "/^subscribe_/"}}) {
        edges {
          node {
            key
            lang
            text
          }
        }
      }
      footerData: allTranslation(filter: {key: {regex: "/^footer_/"}}) {
        edges {
          node {
            key
            lang
            text
          }
        }
      }
      contactData: allTranslation(filter: {key: {regex: "/^contact_section_/"}}) {
        edges {
          node {
            key
            lang
            text
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
      menu_en: getHeaderMenu(lang: "en") {
        slug
        label
        title
        submenu {
          reference
          name
          slug
          label
          title
        }
      }
      menu_es: getHeaderMenu(lang: "es") {
        slug
        label
        title
        submenu {
          reference
          name
          slug
          label
          title
        }
      }
      whatsapp_text_en: getTranslation(lang: "en", key: "whatsapp_contact_text")
      whatsapp_text_es: getTranslation(lang: "es", key: "whatsapp_contact_text")
      footerSmall_en: allSitePage(filter: {context: {legal: {eq: true}, lang: {eq: "en"}}}) {
        edges {
          node {
            path
            context {
              title
            }
          }
        }
      }
      footerSmall_es: allSitePage(filter: {context: {legal: {eq: true}, lang: {eq: "es"}}}) {
        edges {
          node {
            path
            context {
              title
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    let resizeCallback = null;

    if (typeof window !== 'undefined' && window.drift) {
      resizeCallback = () => {
        if (window.innerWidth < 769) {
          window.drift.hide();
        } else {
          window.drift.config({
            locale: lang === 'en' ? 'en-GB' : 'es-ES'
          });
          window.drift.show();
        }
      }

      window.addEventListener('resize', resizeCallback);
      resizeCallback();
    }

    return () => {
      if (typeof window !== 'undefined' && window.drift) {
        window.drift.hide();
      }

      if (resizeCallback) {
        window.removeEventListener('resize', resizeCallback);
      }
    }
  }, []);
  
  const footerData = filterData(data.footerData, lang);
  
  return (
    <>
      <Header menu={data[`menu_${lang}`]} alternateLang={alternateLang} />
      <main className="content-wrapper">
        {children}
        <SubscribeSection 
          lang={lang}
          title={subscribeTitle}
          subtitle={subscribeSubtitle}
          data={filterData(data.subscribeData, lang)}
        />
        <Footer 
          menu={data[`menu_${lang}`]} 
          data={footerData}
        />
        {showContact ? <ContactSection data={filterData(data.contactData, lang)} /> : null}
        <FooterSmall 
          data={footerData}
          links={data[`footerSmall_${lang}`].edges.map(e => e.node)}
        />
        <a 
            className="btn btn-default btn-floating-whatsapp visible-xs"
            href={`https://wa.me/34637889111?text=${encodeURI(whatsappText ? whatsappText : data[`whatsapp_text_${lang}`])}`}
            target="_blank"
        >
            <i className="fa fa-whatsapp white"></i>
        </a>
      </main>
    </>
  )
}

export default Layout
