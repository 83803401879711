import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react";


const Footer = ({ menu = [], data = {} }) => {
    const logoData = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "patricia-properties-logo.svg" }) {
                publicURL
            }
        }
    `);
    
    return (
        <div id="footer">
            <div className="section-content">
                <div className="row">
                    <div className="footer-block col-md-3">
                        <div className="footer-logo t-left">
                            <a href="#home" data-uk-smooth-scroll>
                                <img src={logoData.logo.publicURL} alt="patricia.properties" />
                            </a>
                        </div>
                        <div className="separator-small"></div>
                        <div className="footer-details t-left">
                            <p className="dark f-small no-margin-top">{data.footer_phone ? data.footer_phone : 'Phone'}: <a title={data.footer_phone_title ? data.footer_phone_title : ''} href="tel:+34637889111">+34 637 889 111</a></p>
                            <p className="dark f-small"><a title={data.footer_email_title ? data.footer_email_title : ''} href="mailto:patricia@patricia.properties">patricia@patricia.properties</a></p>
                        </div>
                    </div>
                    <div className="footer-block col-md-3">
                        <div className="t-left">
                            <p className="f-small normal t-left gray2">Patricia Langkemper</p>
                        </div>
                        <div className="separator-small"></div>
                        <div className="t-left">
                            <p className="f-small normal t-left gray2">{data.footer_about ? data.footer_about : ''}</p>
                        </div>
                    </div>
                    <div className="footer-block col-md-2">
                        <div className="t-left">
                            <p className="f-small normal t-left gray2">{data.footer_title_in ? data.footer_title_in : ''}</p>
                        </div>
                        <div className="separator-small"></div>
                        <div className="footer-details t-left">
                            {menu && menu.length ? menu[2].submenu.filter(item => item.reference === 'location').map((item, i) => (
                                <p key={i} className="dark f-small">
                                    <Link to={item.slug} title={item.title}>{item.name}</Link>
                                </p>
                            )) : null}
                        </div>
                    </div>
                    <div className="footer-block col-md-2">
                        <div className="t-left">
                            <p className="f-small normal t-left gray2">{data.footer_title_for ? data.footer_title_for : ''}</p>
                        </div>
                        <div className="separator-small"></div>
                        <div className="footer-details t-left">
                            {menu && menu.length ? menu[2].submenu.filter(item => item.reference === 'opportunity').map((item, i) => (
                                <p key={i} className="dark f-small">
                                    <Link to={item.slug} title={item.title}>{item.name}</Link>
                                </p>
                            )) : null}
                        </div>
                    </div>
                    <div className="footer-block col-md-2">
                        <div className="t-left">
                            <p className="f-small normal t-left gray2">{data.footer_title_connect ? data.footer_title_connect : ''}</p>
                        </div>
                        <div className="separator-small"></div>
                        <div className="footer-details t-left">
                            <p className="dark f-small no-margin-top">
                                {menu && menu.length ? (
                                    <Link title={menu[3].title} to={menu[3].slug}>{menu[3].label}</Link>
                                ) : null}
                            </p>
                            <p className="dark f-small"><a title="Linkedin Patricia Langkemper" href="https://www.linkedin.com/in/patricia-langkemper-91375b83/" target="_blank" rel="noopener noreferrer">Linkedin</a></p>
                            <p className="dark f-small"><a title="Patricia Properties Facebook" href="https://www.facebook.com/patricia.properties.spain/" target="_blank" rel="noopener noreferrer">Patricia Properties</a></p>
                            <p className="dark f-small"><a title="Instagram Una Holandesa en Teruel" href="https://www.instagram.com/una_holandesa_en_teruel/" target="_blank" rel="noopener noreferrer">Una holandesa en Teruel</a></p>
                            <p className="dark f-small">
                                {menu && menu.length ? (
                                    <Link title={menu[4].title} to={menu[4].slug}>{menu[4].label}</Link>
                                ) : null}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer
