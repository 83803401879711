import { Link } from "gatsby"
import React from "react"

const ContactSection = ({ data = {} }) => (
    <div id="offer" className="gray-bg">
        <div className="section-content t-left big-text">
            <h2 className="t-left f-large normal">{data.contact_section_title ? data.contact_section_title : ''}</h2>
            <div className="separator-small"></div>
            <h3 className="t-left f-normal normal">
                {data.contact_section_cta ? (
                    <Link 
                        to={data.contact_section_cta_href ? data.contact_section_cta_href : '#'}
                        title={data.contact_section_cta_title ? data.contact_section_cta_title : ''}
                        className="underline"
                    >{data.contact_section_cta}</Link>
                ) : null}
            </h3>
        </div>
    </div>
);

export default ContactSection;
