import React from "react"
import { Link } from 'gatsby';

const FooterSmall = ({ data, links }) => {
    return (
        <div id="small-footer">
            <div className="section-content">
                <div className="row">
                    <div className="col-md-8 white link-underline t-left">
                        <ul className="footer-links">
                            <li>Pat Swiss Invest &copy; {new Date().getFullYear()}</li>
                            {links.map((l, i) => (
                                <li key={i}>
                                    <Link className="white" alt={l.context.title} to={l.path}>{l.context.title}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <ul className="footer-social">
                            <li className="no-margin">
                                <a title="Linkedin Patricia Langkemper" href="https://www.linkedin.com/in/patricia-langkemper-91375b83/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin white"><span>Linkedin</span></i></a>
                            </li>
                            <li>
                                <a title="Instagram Una holandesa en Teruel" href="https://www.instagram.com/una_holandesa_en_teruel/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram white"><span>Instagram</span></i></a>
                            </li>
                            <li>
                                <a title="Patricia Properties Facebook" href="https://www.facebook.com/patricia.properties.spain/" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook white"><span>Facebook</span></i></a>
                            </li>
                            <li><a title={data.footer_phone_title ? data.footer_phone_title : ''} href="tel:+34637889111"><i className="fa fa-phone white"><span>Phone</span></i></a></li>
                            <li><a title={data.footer_whatsapp_title ? data.footer_whatsapp_title : ''} href="https://wa.me/34637889111"><i className="fa fa-whatsapp white"><span>Whatsapp</span></i></a></li>
                            <li><a title={data.footer_email_title ? data.footer_email_title : ''} href="mailto:patricia@patricia.properties"><i className="fa fa-envelope white"><span>Mail</span></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterSmall;
