import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"


const Header = ({ menu = [], alternateLang = [] }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "patricia-properties-logo.svg" }) {
        publicURL
      }
      logoShort: file(relativePath: { eq: "patricia-properties-logo-short.svg" }) {
          publicURL
      }
    }
  `);

  return (
    <nav className="navigation navbar navbar-default navbar-fixed-top">
      <div className="navbar-header">
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
              <span className="sr-only"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
          </button>
          <Link to={menu && menu.length ? menu[0].slug : '/'} title={menu && menu.length ? menu[0].title : 'Patricia.properties'} className="navbar-brand">
              <img src={data.logo.publicURL} className="hidden-xs hidden-sm" alt="patricia.properties Logo" />
              <img src={data.logoShort.publicURL} className="hidden-md hidden-lg" alt="patricia.properties Logo" />
          </Link>
      </div>
      <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <ul className="nav navbar-nav f-small normal raleway">
          {menu && menu.length ? menu.map((item, i) => (
            <li 
              key={i}
              className={item.submenu.length ? 'dropdown' : ''}
            >
              <Link 
                activeClassName="active" 
                title={item.title}
                to={item.slug}
                className={item.submenu.length ? 'dropdown-toggle' : ''}
                data-toggle={item.submenu.length ? 'dropdown' : ''}
                partiallyActive={item.submenu.length ? true : false}
              >
                {item.label} {item.submenu.length ? (<b className="caret"></b>) : null}
              </Link>
              {item.submenu.length ? (
                <ul className="dropdown-menu f-small normal lato">
                  {item.submenu.map((subitem, ii) => (
                    <li key={ii}>
                      <Link 
                        to={subitem.slug}
                        title={subitem.title}
                      >
                        {subitem.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          )) : null}
          {alternateLang && alternateLang.length ? alternateLang.map((item, i) => (
            <li key={i}>
              <Link 
                rel="alternate" 
                hrefLang={item.lang} 
                activeClassName="active" 
                to={`${item.path.substring(0, 1) !== '/' ? '/' : ''}${item.path}`}
                className="text-uppercase"
              >{item.lang}</Link>
            </li>
          )) : null}
        </ul>
      </div>
    </nav>
  );
}

export default Header
