import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, path, image = null, noindex = false }) {
  const { site, defaultImageSrc } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
          }
        }
        defaultImageSrc: file(sourceInstanceName: {eq: "images"}, name: {eq: "default-sharing-image"}) {
          childImageSharp {
              resize(width: 1200, height: 630) {
                  src
              }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description;
  const imageSrc = `${site.siteMetadata.url}${image ? image : defaultImageSrc.childImageSharp.resize.src}`;

  if (noindex) {
    meta = [
      ...meta,
      {
        name: 'robots',
        content: 'noindex'
      }
    ];
  }
  
  if (path) {
    meta = [
      ...meta,
      {
        property: 'og:url',
        content: path
      }
    ];
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s — ${title && title.length > 43 ? 'patricia.' : site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${title} — ${site.siteMetadata.title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: imageSrc
        },
        {
          property: 'og:locale',
          content: lang === 'en' ? 'en_GB' : 'es_ES'
        },
        {
          property: 'fb:app_id',
          content: '164998841578027'
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: `${title} — ${site.siteMetadata.title}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: 'twitter:image',
          content: imageSrc
        }
      ].concat(meta)}
    >
      {path ? <link rel="canonical" href={path} /> : null}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
