import React, { memo, useRef, useState } from "react"

import addToMailchimp from 'gatsby-plugin-mailchimp';


const SubscribeSection = memo(({ title, subtitle, data, lang }) => {
    
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const inputRef = useRef();

    const removeError = () => {
        setError('');
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let email = inputRef.current.value;
        email = email ? email.trim() : null;
        
        if (!email) {
            setError(data.subscribe_email_error ? data.subscribe_email_error : 'Please, enter a valid email.');
            return;
        }
    
        addToMailchimp(email, { LANG: lang })
            .then(data => {
                if (data.result === 'success') {
                    const duration = 10000;
                    setSuccess(data.msg);
                    setTimeout(() => {
                        setSuccess('');
                    }, duration);
                } else {
                    setError(data.msg);
                }
            })
            .catch(e => {
                // show error
                setError(data.subscribe_error ? data.subscribe_error : 'Ops! there was an error. Please try again later');
            });
    };

    return (
        <div id="newsletter" className="dark-bg">
            <div className="section-content big-text">
                <h2 className="t-left f-large normal white">{title ? title : (data.subscribe_title ? data.subscribe_title : ' ')}</h2>
                {subtitle ? <h3 className="t-left f-normal normal white">{subtitle}</h3> : null}
                <div className="separator-small"></div>
            </div>
            <div className="section-content">
                <div className="subscribe-form">
                    {success ? (
                        <h3 className="t-left f-normal normal white">{success}</h3>
                    ) : (
                    <form onSubmit={handleSubmit} id="notifyMe" className="subscribe-form" method="post">
                        <input 
                            required="required" 
                            ref={inputRef} 
                            data-ng-model="notify" 
                            className={`form-control gray-border ${error ? 'form-error' : ''}`} 
                            name="email" 
                            placeholder={data.subscribe_email_placeholder ? data.subscribe_email_placeholder : 'Enter email'}
                            type="email"
                            onFocus={removeError} />
                        {error ? (
                            <label htmlFor="email" id="email">
                                <span className="error error1 red f-small">{error}</span> 
                            </label> 
                        ) : null}
                        <h3 className="t-left f-normal normal"><button type="submit" className="white underline">{data.subscribe_submit ? data.subscribe_submit : 'Subscribe'}</button></h3>
                    </form>
                    )} 
                </div>
            </div>
        </div>
    );
});

export default SubscribeSection;